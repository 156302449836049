@mixin scroll-shadows {
  overflow-y: scroll;

  background:
    /* Shadow covers */ linear-gradient(
      #ffffff 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: var(--color-white);
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  background-attachment: local, local, scroll, scroll;
}

@mixin vertical-scroll-shadows {
  overflow-x: scroll;

  background:
      /* Shadow covers */ linear-gradient(
        90deg,
        #ffffff 30%,
        rgba(255, 255, 255, 0)
      )
      0 100%,
    linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff 70%) 100% 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 0% 50%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 50%,
    radial-gradient(
        farthest-side at 100% 50%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      100% 100%;
  background-repeat: no-repeat;
  background-color: var(--color-white);
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;

  background-attachment: local, local, scroll, scroll;
}

.has-vertical-scroll-shadow {
  @include vertical-scroll-shadows;
}

.has-scroll-shadow {
  @include scroll-shadows;
}
