$orders: 0, 1, 2, 3, 4, 5, 6, 7, 8;

@each $order in $orders {
  .order-#{$order} {
    order: $order;
  }
}

@media (min-width: 48em) {
  @each $order in $orders {
    .order-#{$order}-desktop {
      order: $order;
    }
  }
}

/* Tailwind like classes */

.opacity-0 {
  opacity: 0;
}

.group:hover .group-hover_opacity-100 {
  opacity: 1;
}

.max-text-50 {
  max-width: 50ch;
}
.max-text-60 {
  max-width: 60ch;
}
.max-text-70 {
  max-width: 70ch;
}
.max-text-80 {
  max-width: 80ch;
}

.capitalize-first {
  display: block;
}
.capitalize-first--inline {
  display: inline-block;
}
.capitalize-first--inline:first-letter,
.capitalize-first:first-letter {
  text-transform: capitalize;
}

.cursor-pointer,
.is-clickable {
  cursor: pointer;
}

.invalid-form-value {
  color: red;
}
