@charset "utf-8";

@import 'assets/styles/_variables.scss';

// ----------------------------------------------------------
//                       R E S E T
// ----------------------------------------------------------

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
}

router-outlet {
  display: none;
}

th {
  user-select: none;
}

// ----------------------------------------------------------
//                        B U L M A   2 / 2
// ----------------------------------------------------------

@import '~node_modules/bulma/sass/utilities/initial-variables';
@import '~node_modules/bulma/sass/utilities/functions';
@import '~node_modules/bulma/sass/utilities/derived-variables';
@import '~node_modules/bulma/sass/utilities/mixins';
@import '~node_modules/bulma/sass/utilities/controls';
@import '~node_modules/bulma/sass/utilities/extends';
@import '~node_modules/bulma/sass/base/_all.sass';
@import '~node_modules/bulma/sass/elements/_all.sass';
@import '~node_modules/bulma/sass/form/_all.sass';
@import '~node_modules/bulma/sass/grid/_all.sass';
@import '~node_modules/bulma/sass/helpers/_all.sass';
@import '~node_modules/bulma/sass/layout/section.sass';
@import '~node_modules/bulma/sass/components/card.sass';
@import '~node_modules/bulma/sass/components/dropdown.sass';
@import '~node_modules/bulma/sass/components/level.sass';
@import '~node_modules/bulma/sass/components/menu.sass';
@import '~node_modules/bulma/sass/components/message.sass';
@import '~node_modules/bulma/sass/components/modal.sass';
@import '~node_modules/bulma/sass/components/navbar.sass';
@import '~node_modules/bulma/sass/components/pagination.sass';
@import '~node_modules/bulma/sass/components/panel.sass';
@import '~node_modules/bulma/sass/components/tabs.sass';

// ----------------------------------------------------------
//                      S O F T B R I K
// ----------------------------------------------------------
@import 'assets/styles/animations.scss';
@import 'assets/styles/utility.scss';
@import 'assets/styles/mixins.scss';
@import 'assets/styles/layout.scss';
@import 'assets/styles/components.scss';
