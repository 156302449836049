.no-data {
  text-align: center;
  margin-top: 38%;
  font-size: 2em;
  color: #bebbbb;
  user-select: none;
}

// Icons

.icon.is-tiny {
  height: 0.75rem;
  width: 0.75rem;
  i {
    font-size: 12px;
  }
}

// Forms

.optional {
  color: var(--color-grey-lighter);
  font-size: 0.825rem;
  text-transform: uppercase;
  margin-left: 0.125rem;
}

.is-muted {
  color: var(--color-grey-light);
}

.optional.is-small {
  font-size: 0.7rem;
}

label.checkbox > span:last-child {
  margin-left: 0.25rem;
}

label.checkbox.is-small {
  font-size: 0.825rem;
}

input.checkbox[type='checkbox'] {
  width: 0.75rem;
  height: 0.75rem;
}

// Action sepratator

.action-separator {
  display: inline-block;
  background-color: var(--color-grey-lighter);
  width: 0.25rem;
  height: 1.25rem;
  margin: 0 1.125rem;
  border-radius: 3px;
}

// Voice input
:root {
  --voice-player-stop-bg: var(--color-danger);
  --voice-player-bg: var(--color-purple);
  --voice-player-bg-light: var(--color-purple-light);
}

// Joyride
.backdrop-target {
  border-radius: 4px;
}
joyride-step {
  .joyride-step__container {
    border-radius: 4px;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.125) !important;
  }
  joyride-button {
    .joyride-button {
      background-color: #384c8b !important;
      color: var(--color-white) !important;
      border-radius: 4px !important;
      &:hover {
        background-color: #344782 !important;
      }
    }
  }
}

// Quill

.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
