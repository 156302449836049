@charset "utf-8";

@use 'sass:color';
@use 'sass:map';

// ----------------------------------------------------------
//                        B U L M A   1 / 2
// ----------------------------------------------------------

$sb-color-green-light: #63b194;
$sb-color-green: #428f74;
$sb-color-green-dark: #439277;
$sb-color-blue-light: #51629c;
$sb-color-blue: #384c8b;
$sb-color-blue-dark: #293a7c;
$sb-color-yellow-light: #f4d170;
$sb-color-yellow: #ebc45a;
$sb-color-yellow-dark: #e1a948;
$sb-color-purple-light: #afa2c0;
$sb-color-purple: #8474a3;
$sb-color-purple-dark: #67518c;
$sb-color-red: #f03a5f;

$sb-colors: (
  // brand
  'green-light': $sb-color-green-light,
  'green': $sb-color-green,
  'green-dark': $sb-color-green-dark,
  'blue-light': $sb-color-blue-light,
  'blue': $sb-color-blue,
  'blue-dark': $sb-color-blue-dark,
  'yellow-light': $sb-color-yellow-light,
  'yellow': $sb-color-yellow,
  'yellow-dark': $sb-color-yellow-dark,
  'purple-light': $sb-color-purple-light,
  'purple': $sb-color-purple,
  'purple-dark': $sb-color-purple-dark,
  // other
  'red': $sb-color-red,
  'white': #fff,
  'black': #000,
  // derived
  'success': $sb-color-green-light,
  'danger': $sb-color-red
);

@function rgb-list($color) {
  @return (red($color), green($color), blue($color));
}

$sb-colors-rgb: ();
@each $name, $color in $sb-colors {
  $sb-colors-rgb: map-merge($sb-colors-rgb, (#{$name}-rgb: rgb-list($color)));
}

// css variables
:root {
  @each $name, $color in map.merge($sb-colors, $sb-colors-rgb) {
    --color-#{$name}: #{$color};
  }
}

// bulma overrides
$primary: $sb-color-blue;
$info: $sb-color-blue-light;
$blue: $sb-color-blue;
$yellow: $sb-color-yellow;
$green: $sb-color-green;
$red: $sb-color-red;

:root {
  --color-blue-rgb: 255, 0, 0;
  --color-blue-lighter: red;
  --color-teal: red;
  --color-teal: red;
  --color-grey-light-rgb: 167, 167, 167;
  --color-grey-light: #a7a7a7;
  --color-grey-lighter-rgb: 216, 216, 216;
  --color-grey-lighter: #d8d8d8;
  --color-grey-lightest: #e7e7e7;
  --color-grey-lightester: #f7f7f7;
  --color-grey-rgb: 91, 91, 91;
  --color-grey: #5b5b5b;
  --color-mint-light: red;
  --color-mint-rgb: 255, 0, 0;
  --color-mint: red;
  --color-primary: #{$primary};
  --color-primary-rgb: #{rgb-list($primary)};
  --h-top-menu: 52px;
  --shadow-nav: 2px 2px 2px rgba(0, 0, 0, 0.2);
  --shadow-action: 0 -8px 8px rgba(0, 0, 0, 0.1);
  --shadow-dropdown: 0 0.5em 1em 0.125em rgba(0, 0, 0, 0.2);
  --border-radius-small: 4px;
  --border-radius-medium: 6px;
}
