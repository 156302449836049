.animate-pulse {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-fade-in {
  animation-name: fade-in;
  animation-duration: 300ms;
  animation-iteration-count: 1;
}

.animate-fade-out {
  animation-name: fade-in;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-direction: reverse;
}

.animate-explode {
  animation-name: explode;
  animation-duration: 150ms;
  animation-iteration-count: 1;
}

.animate-dropout {
  animation-name: dropout;
  animation-duration: 350ms;
  animation-iteration-count: 1;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes from-below {
  from {
    transform: translateY(2rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes explode {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes dropout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.3) rotateZ(360deg);
  }
}
