softbrik-root {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 52px;
  grid-template-areas:
    'top   '
    'body  '
    'body  '
    'action';
  @media (min-width: 64em) {
    display: grid;
    grid-template-columns: 300px repeat(4, 1fr);
    grid-template-areas:
      'top    top    top    top    top'
      'body   body   body   body   body'
      'body   body   body   body   body'
      'action action action action action';
  }
  &.is-plain {
    display: block !important;
    @media (max-width: 48em) {
      overflow: hidden;
    }
  }
  &.with-sidebar {
    @media (min-width: 64em) {
      display: grid;
      grid-template-columns: 300px repeat(4, 1fr);
      grid-template-areas:
        'top  top    top    top    top'
        'side body   body   body   body'
        'side body   body   body   body'
        'side action action action action';
      &--collapsed {
        grid-template-columns: 48px repeat(4, 1fr);
        transition: grid-template-columns 350ms;
      }
    }
  }
}

softbrik-top-menu {
  grid-area: top;
  position: sticky;
  top: 0;
  z-index: 40;
}

softbrik-sidebar,
softbrik-side-menu {
  display: none;
  grid-area: side;
  @media (min-width: 64em) {
    display: block;
  }
}

softbrik-action-bar {
  grid-area: action;
}

.page,
softbrik-page {
  grid-area: body;
  overflow-x: auto; // needed for side-scroll
  overflow-y: visible; // needed to cut down scrolls in file brik
  position: relative;
  padding: 2rem;
}
